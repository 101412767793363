import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../slices/staffSlice';
import { fetchRequests } from '../../Requests/getRequests';
import { show } from '../../slices/toastSlice';
import { authStaff, loanStates } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useLoans } from '../../hooks';
import { Topbar, Sidebar, LoansTable, Loader } from "../../components";

function Index() {

    const { isLoading, loans, metadata } = useLoans(
        { loanType : null, state : null, active : false, completed : false, rejected : true, endpoint : null }
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = useSelector(selectAccessToken);
    const [isPagLoading, setIsPagLoading] = useState(false);
    const [pagLoans, setPagLoans] = useState(null);
    const [pagMetadata, setPagMetadata] = useState(null);

    let loadingInc = isPagLoading ? isPagLoading : isLoading;

    // create a function to handle pagination fetching
    const handlePagination = async(page) => {
        setIsPagLoading(true);

        let headers = authStaff(accessToken);
        let url = `/loans/staff/rejected-loans`;
        let params = { 
            state : loanStates.rejected, 
            page : page || 1 
        };

        let res = await fetchRequests(url, "GET", headers, params, {});
        setIsPagLoading(false);

        if(res?.code === 401){
            navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        // set the loans and metadata to the paginated loans and metadata
        setPagLoans(res.loans);
        setPagMetadata(res.metadata);
    }
    
    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>

                <section className='section-container'>
                    <div className='flex items-center justify-between mb-4 md:mb-6'>
                        <h3 className='text-3xl font-medium leading-6 text-display mb-6 inline-flex items-center'>
                            Rejected Applications {loadingInc && <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} />}
                        </h3>
                    </div>
                    
                    <LoansTable 
                        loans={pagLoans ? pagLoans : loans} 
                        metadata={pagMetadata ? pagMetadata : metadata}
                        handlePagination={handlePagination}
                    />

                </section>

            </main>

        </div>
    )
}

export default Index
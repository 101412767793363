import { useState } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { FormSelect } from '../../components';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function Chart({ yearDisbursed, yearRepaid }) {

    const [paymentType, setPaymentType] = useState('Disbursed');

    const handleChange = (value) => {
        setPaymentType(value);
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Number of customer accounts',
            },
        },
        scales : {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
                ticks: {
                    beginAtZero: true,
                },
            },
        }
        // indexAxis: 'x',
        
    };

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const data = {
        type : 'bar',
        labels : labels,
        datasets: [{
            label: paymentType === "Disbursed" ? 'Disbursed' : 'Repaid',
            data: paymentType === "Disbursed" ? yearDisbursed : yearRepaid,
            backgroundColor: 'rgba(0,0,0,1)',
            borderWidth: 1,
            borderSkipped: 'bottom',
            borderRadius: 5,
        }],
    };

    return (
        <div className='w-full h-[30rem] py-5 px-8 border rounded-md shadow-sm'>
            <div className='flex items-center justify-between mb-6'>
                <h4 className='text-lg font-bold leading-6 text-display mt-2'>
                    Overview
                </h4>

                <div className='w-48'>
                    <FormSelect 
                        name={"paymentType"}
                        id={"paymentType"}
                        content={[
                            { id : 1, name : "Disbursement", value : "Disbursed"}, 
                            { id : 2, name : "Repayment", value : "Repaid"}
                        ]}
                        value={paymentType}
                        setValue={handleChange}
                    />
                </div>
            </div>

            <Bar options={options} data={data} />
        </div>
    )
}

export default Chart
import React, { Fragment } from 'react'

function BureauReport({ report }) {
    return (
        <>
        {report?.response?.message === "No records found! Thank You!" ? 
            <tr colSpan="2" className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-bold text-center'>
                    {report?.response?.message}
                </td>
            </tr>
        : 
        <Fragment>
            <tr colSpan="2" className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                <td className='px-4 py-3 font-bold'>Personal Information</td>
            </tr>

            {report?.telephoneHistory.map((item, index) => (
                <tr key={index + 15} className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Mobile Phone Number
                    </td>
                    <td className="px-4 py-3">
                        {item?.mobileTelephoneNumber}
                    </td>
                </tr>
            ))}

            {report?.identificationHistory.map((item, index) => (
                <tr key={index + 11} className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Idenfitication Type / Number
                    </td>
                    <td className="px-4 py-3">
                        {item?.identificationType} - {item?.identificationNumber}
                    </td>
                </tr>
            ))}

            {report?.addressHistory.map((item, index) => (
                <tr key={index + 10} className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Address
                    </td>
                    <td className="px-4 py-3">
                        {item?.address1} - {item?.addressTypeInd}
                    </td>
                </tr>
            ))}

            {report?.employmentHistory.map((item, index) => (
                <tr key={index + 12} className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Employment
                    </td>
                    <td className="px-4 py-3">
                        {item?.occupation}
                    </td>
                </tr>
            ))}



            <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'></td>
                <td className='px-4 py-3'></td>
            </tr>

            <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3 font-bold'>Delinquency Rating</td>
            </tr>

            <tr className="px-4 py-6 border-b">
                <td className="px-4 py-3">
                    Highest Delinquency Rating
                </td>
                <td className="px-4 py-3">
                    {report?.highestDelinquencyRating?.highestDelinquencyRating}
                </td>
            </tr>




            <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'></td>
                <td className='px-4 py-3'></td>
            </tr>

            <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3 font-bold'>Credit Account Summary</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Total Monthly Installment</td>
                <td className='px-4 py-3'>{report?.creditAccountSummary?.totalMonthlyInstalmentGHS}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Total Outstanding Debt</td>
                <td className='px-4 py-3'>{report?.creditAccountSummary?.totalOutstandingdebtGHS}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Total Account Arrear</td>
                <td className='px-4 py-3'>{report?.creditAccountSummary?.totalAccountInArrearGHS}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Amount Arrear</td>
                <td className='px-4 py-3'>{report?.creditAccountSummary?.amountarrearGHS}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Total Account in good condition</td>
                <td className='px-4 py-3'>{report?.creditAccountSummary?.totalaccountinGoodconditionGHS}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Total Number of Judgement</td>
                <td className='px-4 py-3'>{report?.creditAccountSummary?.totalNumberofJudgementGHS}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Total Judgement Amount</td>
                <td className='px-4 py-3'>{report?.creditAccountSummary?.totalJudgementAmountGHS}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Last Judgement Date</td>
                <td className='px-4 py-3'>{report?.creditAccountSummary?.lastJudgementDateGHS}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Total Number of Dishonoured</td>
                <td className='px-4 py-3'>{report?.creditAccountSummary?.totalNumberofDishonouredGHS}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Total Dishonoured Amount</td>
                <td className='px-4 py-3'>{report?.creditAccountSummary?.totalDishonouredAmountGHS}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Last bounced cheque date</td>
                <td className='px-4 py-3'>{report?.creditAccountSummary?.lastBouncedChequesDateGHS}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Total Number of Accounts (GHS)</td>
                <td className='px-4 py-3'>{report?.creditAccountSummary?.totalNumberofAccountsGHS}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Total Active Accounts (GHS)</td>
                <td className='px-4 py-3'>{report?.creditAccountSummary?.totalActiveAccountsGHS}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Total Closed Accounts (GHS)</td>
                <td className='px-4 py-3'>{report?.creditAccountSummary?.totalClosedAccountsGHS}</td>
            </tr>



            <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'></td>
                <td className='px-4 py-3'></td>
            </tr>

            <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3 font-bold'>Account Rating</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Home Loan Accounts (Good)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfHomeLoanAccountsGood}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Home Loan Accounts (Bad)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfHomeLoanAccountsBad}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Auto Loan Accounts (Good)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfAutoLoanccountsGood}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Auto Loan Accounts (Bad)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfAutoLoanccountsBad}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Study Loan Accounts (Good)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfStudyLoanAccountsGood}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Study Loan Accounts (Bad)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfStudyLoanAccountsBad}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Personal Loan Accounts (Good)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfPersonalLoanAccountsGood}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Personal Loan Accounts (Bad)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfPersonalLoanAccountsBad}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Credit Card Accounts (Good)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfCreditCardAccountsGood}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Credit Card Accounts (Bad)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfCreditCardAccountsBad}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Retail Accounts (Good)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfRetailAccountsGood}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Retail Accounts (Bad)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfRetailAccountsBad}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Joint Loan Accounts (Good)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfJointLoanAccountsGood}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Joint Loan Accounts (Bad)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfJointLoanAccountsBad}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Telecom Accounts (Good)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfTelecomAccountsGood}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Telecom Accounts (Bad)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfTelecomAccountsBad}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Other Accounts (Good)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfOtherAccountsGood}</td>
            </tr>

            <tr className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'>Number of Other Accounts (Bad)</td>
                <td className='px-4 py-3'>{report?.accountRating.noOfOtherAccountsBad}</td>
            </tr>



            <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'></td>
                <td className='px-4 py-3'></td>
            </tr>

            <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3 font-bold'>Credit Agreement Summary</td>
            </tr>

            {report?.creditAgreementSummary.map((item, index) => (
                <Fragment key={index + 20}>
                <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
                    <td className='px-4 py-3 font-bold'>{index + 1}</td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Date Account Opened
                    </td>
                    <td className="px-4 py-3">
                        {item?.dateAccountOpened}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Subscriber Name
                    </td>
                    <td className="px-4 py-3">
                        {item?.subscriberName}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Account Number
                    </td>
                    <td className="px-4 py-3">
                        {item?.accountNo}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Indicator Description
                    </td>
                    <td className="px-4 py-3">
                        {item?.indicatorDescription}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Opening Balance Amount
                    </td>
                    <td className="px-4 py-3">
                        {item?.openingBalanceAmt}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Currency
                    </td>
                    <td className="px-4 py-3">
                        {item?.currency}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Current Balance Debt Indicator
                    </td>
                    <td className="px-4 py-3">
                        {item?.currentBalanceDebitInd}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Current Balance Amount
                    </td>
                    <td className="px-4 py-3">
                        {item?.currentBalanceAmt}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Installment Amount
                    </td>
                    <td className="px-4 py-3">
                        {item?.instalmentAmount}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Amount Overdue
                    </td>
                    <td className="px-4 py-3">
                        {item?.amountOverdue}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Closed Date
                    </td>
                    <td className="px-4 py-3">
                        {item?.closedDate}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Changed on date
                    </td>
                    <td className="px-4 py-3">
                        {item?.changedOnDate}
                    </td>
                </tr>
                </Fragment>
            ))}



            <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3'></td>
                <td className='px-4 py-3'></td>
            </tr>

            <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
                <td className='px-4 py-3 font-bold'>Account Monthly Payment History</td>
            </tr>

            {report?.accountMonthlyPaymentHistory.map((item, index) => (
                <Fragment key={index + 30}>
                <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
                    <td className='px-4 py-3 font-bold'>{index + 1}</td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Header
                    </td>
                    <td className="px-4 py-3">
                        {item?.header}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Display Information
                    </td>
                    <td className="px-4 py-3">
                        {item?.displayText}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Date Account Opened
                    </td>
                    <td className="px-4 py-3">
                        {item?.dateAccountOpened}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Account Number
                    </td>
                    <td className="px-4 py-3">
                        {item?.accountNo}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Currency
                    </td>
                    <td className="px-4 py-3">
                        {item?.currency}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Repayment Frequency
                    </td>
                    <td className="px-4 py-3">
                        {item?.repaymentFrequency}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Opening Balance Amount
                    </td>
                    <td className="px-4 py-3">
                        {item?.openingBalanceAmt}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Current Balance Amount
                    </td>
                    <td className="px-4 py-3">
                        {item?.currentBalanceAmt}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Installment Amount
                    </td>
                    <td className="px-4 py-3">
                        {item?.instalmentAmount}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Amount Overdue
                    </td>
                    <td className="px-4 py-3">
                        {item?.amountOverdue}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Closed Date
                    </td>
                    <td className="px-4 py-3">
                        {item?.closedDate}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Last updated date
                    </td>
                    <td className="px-4 py-3">
                        {item?.lastUpdatedDate}
                    </td>
                </tr>
                <tr className="px-4 py-6 border-b">
                    <td className="px-4 py-3">
                        Company
                    </td>
                    <td className="px-4 py-3">
                        {item?.company}
                    </td>
                </tr>
                <tr className='px-4 py-6 border-b'>
                    <td colSpan={2} className='px-4 py-3'>
                        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                                <table className='min-w-full'>
                                    <thead className='text-xs font-semibold tracking-wide text-left text-white uppercase border-b bg-gray-700'>
                                        <tr>
                                            <th colSpan={2} className='px-4 py-3'>Previous Year</th>
                                            <th colSpan={2} className='px-4 py-3'>Current Year</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="px-4 py-6 border-b">
                                            <td className="px-4 py-3">{item?.mH24}</td>
                                            <td className="px-4 py-3">{item?.m24}</td>
                                            <td className="px-4 py-3">{item?.mH01}</td>
                                            <td className="px-4 py-3">{item?.m01}</td>
                                        </tr>
                                        <tr className="px-4 py-6 border-b">
                                            <td className="px-4 py-3">{item?.mH23}</td>
                                            <td className="px-4 py-3">{item?.m23}</td>
                                            <td className="px-4 py-3">{item?.mH02}</td>
                                            <td className="px-4 py-3">{item?.m02}</td>
                                        </tr>
                                        <tr className="px-4 py-6 border-b">
                                            <td className="px-4 py-3">{item?.mH22}</td>
                                            <td className="px-4 py-3">{item?.m22}</td>
                                            <td className="px-4 py-3">{item?.mH03}</td>
                                            <td className="px-4 py-3">{item?.m03}</td>
                                        </tr>
                                        <tr className="px-4 py-6 border-b">
                                            <td className="px-4 py-3">{item?.mH21}</td>
                                            <td className="px-4 py-3">{item?.m21}</td>
                                            <td className="px-4 py-3">{item?.mH04}</td>
                                            <td className="px-4 py-3">{item?.m04}</td>
                                        </tr>
                                        <tr className="px-4 py-6 border-b">
                                            <td className="px-4 py-3">{item?.mH20}</td>
                                            <td className="px-4 py-3">{item?.m20}</td>
                                            <td className="px-4 py-3">{item?.mH05}</td>
                                            <td className="px-4 py-3">{item?.m05}</td>
                                        </tr>
                                        <tr className="px-4 py-6 border-b">
                                            <td className="px-4 py-3">{item?.mH19}</td>
                                            <td className="px-4 py-3">{item?.m19}</td>
                                            <td className="px-4 py-3">{item?.mH06}</td>
                                            <td className="px-4 py-3">{item?.m06}</td>
                                        </tr>
                                        <tr className="px-4 py-6 border-b">
                                            <td className="px-4 py-3">{item?.mH18}</td>
                                            <td className="px-4 py-3">{item?.m18}</td>
                                            <td className="px-4 py-3">{item?.mH07}</td>
                                            <td className="px-4 py-3">{item?.m07}</td>
                                        </tr>
                                        <tr className="px-4 py-6 border-b">
                                            <td className="px-4 py-3">{item?.mH17}</td>
                                            <td className="px-4 py-3">{item?.m17}</td>
                                            <td className="px-4 py-3">{item?.mH08}</td>
                                            <td className="px-4 py-3">{item?.m08}</td>
                                        </tr>
                                        <tr className="px-4 py-6 border-b">
                                            <td className="px-4 py-3">{item?.mH16}</td>
                                            <td className="px-4 py-3">{item?.m16}</td>
                                            <td className="px-4 py-3">{item?.mH09}</td>
                                            <td className="px-4 py-3">{item?.m09}</td>
                                        </tr>
                                        <tr className="px-4 py-6 border-b">
                                            <td className="px-4 py-3">{item?.mH15}</td>
                                            <td className="px-4 py-3">{item?.m15}</td>
                                            <td className="px-4 py-3">{item?.mH11}</td>
                                            <td className="px-4 py-3">{item?.m11}</td>
                                        </tr>
                                        <tr className="px-4 py-6 border-b">
                                            <td className="px-4 py-3">{item?.mH14}</td>
                                            <td className="px-4 py-3">{item?.m14}</td>
                                            <td className="px-4 py-3">{item?.mH12}</td>
                                            <td className="px-4 py-3">{item?.m12}</td>
                                        </tr>
                                        <tr className="px-4 py-6 border-b">
                                            <td className="px-4 py-3">{item?.mH13}</td>
                                            <td className="px-4 py-3">{item?.m13}</td>
                                            <td className="px-4 py-3">{item?.mH13}</td>
                                            <td className="px-4 py-3">{item?.m13}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </td>
                </tr>
                </Fragment>
            ))}
        </Fragment>
        }
        </>
    )
}

export default BureauReport
import { BureauReport } from "../../../components";

function Index({ loan }) {

    return (
        <div className='application-content flex flex-col justify-center space-y-4'>
            
            {/* show this if credit bureau information is available */}
            <div className='rounded-md'>
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                        <table className='min-w-full'>
                            <thead className='text-xs font-semibold tracking-wide text-left text-white uppercase border-b bg-gray-700'>
                                <tr>
                                    <th scope="col" className='px-4 py-3 md:w-[50%]'>Requested Information</th>
                                    <th scope="col" className='px-4 py-3 md:w-[50%]'>Details</th>
                                </tr>
                            </thead>

                            <tbody>
                                {loan?.clientCreditBureauReport !== undefined && loan?.clientCreditBureauReport?.message !== "No records found! Thank You!" ? 
                                    <BureauReport 
                                        report={loan?.clientCreditBureauReport}
                                    />
                                : 
                                <tr className="px-4 py-6 border-b">
                                    <td colSpan={2} className="px-4 py-3 text-center">
                                        <p>No records found! Thank you</p>
                                    </td>
                                </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* show this if credit bureau information is available */}

        </div>
    )
}

export default Index
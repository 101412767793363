import Card from './Card';
import Chart from './Chart';
import Recents from './Recents';

function Overview({ payments }) {
    return (
        <div className='space-y-10'>
            <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4'>
                <Card 
                    title={"Total disbursed"}
                    disburseData={{ todayTotal: payments?.totalDisbursements }}
                />
                <Card 
                    title={"Total Repayments"}
                    disburseData={{ todayTotal: payments?.totalRepayments }}
                />
                <Card 
                    title={"Disbursed this month"}
                    disburseData={{ todayTotal: payments?.totalDisbursementThisMonth }}
                />
                <Card 
                    title={"Repayments this month"}
                    disburseData={{ todayTotal: payments?.totalRepaymentThisMonth }}
                />
            </div>

            <div className='grid grid-cols-1 md:grid-cols-5 gap-4'>
                <div className="col-span-3">
                    <Chart 
                        yearDisbursed={payments?.yearDisbursed}
                        yearRepaid={payments?.yearRepaid}
                    />
                </div>

                <div className="col-span-2">
                    <Recents 
                        recPayments={payments?.recPayments}
                    />
                </div>
            </div>
        </div>
    )
}

export default Overview
import React from 'react'
import { numberFormatter } from '../../utils/financials'
import { cediSign } from '../../utils/constants';

function Recents({ recPayments }) {
    return (
        <div className='w-full h-[30rem] py-5 px-8 border rounded-md shadow-sm'>
            <h4 className='text-lg font-bold leading-6 text-display mt-2 mb-6'>
                Recent Payments
            </h4>

            <ul className='space-y-4'>
                {recPayments?.map((item, index) => (
                    <li key={index} className='flex flex-row items-center justify-between'>
                        <div className='flex flex-col'>
                            <span className='font-bold text-sm'>{item.name}</span>
                            <span className='text-xs text-gray-400'>{item.date}</span>
                        </div>
                        <div>
                            <span className={'text-sm font-bold tabular-nums' + (item.type === 'repay' ? ' text-green-500' : ' text-red-500')}>
                                {cediSign} {numberFormatter(item.amount)}
                            </span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Recents
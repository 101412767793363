import { useState, useEffect } from 'react';
import { Topbar, Sidebar, Heading, Loader, Debtor, Pagination, Search } from "../../../components";
import { authStaff } from "../../../utils/constants";
import { adminPortalRequest } from "../../../Requests/getRequests";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken } from "../../../slices/staffSlice";
import { show } from '../../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function Index() {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [clientList, setClientList] = useState();
    const [defaultData, setDefaultData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [metadata, setMetadata] = useState(null);
    const [currentPage, setCurrentPage] = useState(metadata?.currentPage || 1);
    const nPages = metadata?.totalPages;

    // create a function to handle pagination fetching
    const handlePagination = async(page) => {
        setIsLoading(true);

        let headers = authStaff(accessToken);
        let url = `/auth/staff/client/list`;
        let params = { page : page || 1 };

        let res = await adminPortalRequest(url, "GET", headers, params, {});
        setIsLoading(false);

        if(res?.code === 401){
            navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        // set the loans and metadata to the paginated loans and metadata
        setClientList(res.clients);
        setDefaultData(res.clients);
        setMetadata(res.metadata);
    }

    useEffect(() => {
        let unsubscribed = false;
        const getCounts = async(accessToken) => {
            setIsLoading(true);

            let clientUrl = `/auth/staff/client/list`;
            let headers = authStaff(accessToken);
            let params = { page : 1 };

            let res = await adminPortalRequest(clientUrl, "GET", headers, params, {});
            setIsLoading(false);
            
            if(!unsubscribed){
                if(res?.code === 401){
                    navigate("/login");
                    dispatch(show({
                        type : "error",
                        state : true,
                        message : "Your session has expired. Please login to continue."
                    }))
                    return;
                } 
                
                if(res?.err){
                    dispatch(show({
                        type : "error",
                        state : true,
                        message : `${res?.err}`
                    }))
                    return;
                }
                else {
                    setClientList(res.clients);
                    setDefaultData(res.clients);
                    setMetadata(res.metadata);
                }
            }
        }

        getCounts(accessToken);

        return () => {
            unsubscribed = true
        }
    },[accessToken, dispatch, navigate])

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>
                <section className="section-container">
                    <div className='flex items-center justify-between mb-3'>
                        <div className='inline-flex items-center space-x-3'>
                            <Heading 
                                text={"Clients"}
                                size="text-3xl font-bold tracking-tight text-gray-700"
                            />

                            {clientList && 
                                <div className='space-x-2'>
                                    <span className='text-sm text-gray-500'>{clientList?.length}</span>
                                    <span className='text-sm px-2.5 py-1 bg-emerald-100 text-emerald-700 rounded-full'>
                                        {clientList.filter(item => item.isVerified === true).length} <span className='text-emerald-600'>verified</span>
                                    </span>
                                </div>
                            }

                            {isLoading && <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} />}
                            
                        </div>

                        <Search 
                            data={clientList}
                            setData={setClientList}
                            placeholder="Search clients"
                            defaultData={defaultData}
                        />
                    </div>


                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <table className='min-w-full'>
                                <thead className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-gray-50'>
                                    <tr>
                                        <th scope="col" className='px-4 py-3 w-[20%]'>Name</th>
                                        <th scope="col" className='px-4 py-3 w-[30%]'>Email Address</th>
                                        <th scope="col" className='px-4 py-3'>Phone Number</th>
                                        <th scope="col" className='px-4 py-3'>Created On</th>
                                        <th scope="col" className='px-4 py-3'>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {clientList && clientList?.length > 0 ? (
                                        clientList?.map((item, index) => (
                                            <Debtor 
                                                key={index}
                                                item={item}
                                                setClientList={setClientList}
                                            />
                                        ))
                                    ) : (
                                        <tr className="px-4 py-6 border-b hover:bg-gray-100">
                                            <td colSpan="5" className='px-4 py-3 text-center font-medium'>
                                                No clients
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            {clientList && (
                                <Pagination 
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    length={metadata.totalCount}
                                    handlePagination={handlePagination}
                                />
                            )}
                        </div>
                    </div>

                </section>
            </main>

        </div>
    )
}

export default Index
import { useEffect, useState } from 'react';
import { Topbar, Sidebar, Loader, PaymentOverview } from "../../components";
import Table from './Table';
import { Tab } from '@headlessui/react';
import { usePayments } from '../../hooks';

function Index() {

    const { isLoading, payments } = usePayments();

    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    let loadingState = isLoading || loading;

    useEffect(() => {
        document.title = `Payment Transactions - ${process.env.REACT_APP_SITE_NAME}`;
    },[])

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>

                <section className='section-container'>
                    
                    <div className='flex items-center justify-between mb-10'>
                        <h3 className='text-lg lg:text-3xl font-medium leading-6 text-display inline-flex items-center'>
                            Payments {loadingState && <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} />}
                        </h3>
                        
                    </div>
                    
                    <Tab.Group>
                        <Tab.List className={`inline-flex px-1 py-1 rounded-md space-x-0.5 p-0.5 bg-gray-100`}>
                            <Tab className={({ selected }) => classNames('px-2 py-1 md:px-3 md:py-1.5', selected ? 'bg-white rounded-md font-medium shadow-sm' : 'hover:bg-white hover:rounded-md')}>
                                Overview
                            </Tab>
                            <Tab className={({ selected }) => classNames('px-2 py-1 md:px-3 md:py-1.5', selected ? 'bg-white rounded-md font-medium shadow-sm' : 'hover:bg-white hover:rounded-md')}>
                                Text Messages
                            </Tab>
                        </Tab.List>

                        <Tab.Panels className="mt-2">
                            <Tab.Panel className={classNames('rounded-xl bg-white py-3')}>
                                <PaymentOverview 
                                    payments={payments} 
                                />
                            </Tab.Panel>

                            <Tab.Panel className={classNames('rounded-xl bg-white py-3')}>
                                <Table 
                                    transactions={transactions} 
                                    setTransactions={setTransactions} 
                                    setLoading={setLoading} 
                                />
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                    
                </section>

            </main>
        </div>
    )
}

export default Index
import { useEffect, useState } from 'react'
import { usePaymentMessages } from '../../hooks';
import { collectionStates } from '../../utils/constants';

function Filter({ setLoading, setTransactions }) {

    const [matched, setMatched] = useState(collectionStates[0].value);

    const { isLoading, transactions } = usePaymentMessages({ state : matched });

    useEffect(() => {
        setLoading(isLoading);
        setTransactions(transactions);
    },[isLoading, transactions, setLoading, setTransactions])

    return (
        <div className='w-44 lg:w-[400px] flex items-center'>
            <label htmlFor='state' className='w-16 lg:w-36 hidden lg:block'>
                Filter By:
            </label>

            <select id="state" name="state" className="block w-full rounded-md border py-2 px-1.5 text-gray-900 shadow-sm focus:outline-none sm:max-w-xs sm:text-sm sm:leading-6" onChange={(e) => setMatched(e.target.value)}>
                {collectionStates.map((item) => (
                    <option key={item.id} value={item.value}>
                        {item.name}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default Filter